import SupportPageDrawing from '@/shared/components/SupportDrawing';
import { fontSizes } from '@/shared/styles';
import styled from 'styled-components';

import { NormalText, TitleText } from '@/shared/components/Text.styled';

export const Break = styled.div`
  height: 5px;
`;

export const SupportView = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
  background: ${(p) => p.theme.colors.backgrounds.app};
  padding-top: 10px;
  padding-bottom: 15px;
  height: 100%;
  color: ${(p) => p.theme.colors.text.secondary};
  text-align: center;
`;

export const Drawing = styled(SupportPageDrawing)`
  width: 100%;
  height: auto;
  max-height: 250px;
`;

export const Headline = styled(TitleText)`
  font-size: ${fontSizes.giant};
`;

export const SupportText = styled(NormalText.withComponent('div'))`
  font-size: 1rem;
  text-wrap: pretty;
`;

export const StyledLink = styled.a`
  margin-top: auto; // Float this to the bottom
  width: 100%;
  height: min-content;
`;

export const BankAccountAndPersonalInformationView = styled.div`
  display: grid;
  gap: 16px;
  text-align: center;
  margin: 20px 0;
  width: 100%;
`;

export const SectionHeader = styled.div`
  text-align: center;
  margin-bottom: 10px;
`;

export const ModalInputField = styled.div`
  margin-bottom: 30px;
  margin-top: 30px;
`;

export const UpdateInfoInstructionWrapper = styled.div`
  margin-bottom: 30px;
`;

export const UpdateInfoRequestCompleteMgsWrapper = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: center;
`;

export const UpdateInfoRequestDropDownWrapper = styled.div``;
