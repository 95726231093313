/**
 * A reusable Transaction List Component that can be used to
 * render transactions in different contexts within a divided card.
 */
import styled from 'styled-components';

import { ReactComponent as PayoutIcon } from '@/shared/assets/money-plus-line.svg';
import DividedCard from '@/shared/components/DividedCard';
import { NormalText, SubTitleText } from '@/shared/components/Text.styled';
import { formatCentsAsCurrency } from '@/shared/helpers';
import { colors, fontSizes, fontWeights } from '@/shared/styles';
import { Customer, Payout } from '@/shared/types';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { ReactElement } from 'react';

dayjs.extend(utc);
dayjs.extend(timezone);

const PayoutGroup = styled.div`
  background: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
const IconBackground = styled.div`
  background: ${(p) => p.theme.colors.graphics.primary};
  color: ${colors.trueWhite};
  width: 40px;
  min-width: 40px;
  height: 40px;
  min-height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Description = styled.div`
  font-size: ${fontSizes.medium};
  font-weight: ${fontWeights.bold};
  overflow: hidden;
  min-width: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 180px;
`;

const PayoutListItemContainer = styled.div`
  display: flex;
  gap: 16px;
  justify-content: space-between;
  width: 100%;
`;

const PayoutListItem = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
`;

const PayoutAmount = styled.div`
  font-size: ${fontSizes.medium};
  font-weight: ${fontWeights.bold};
  display: flex;
  align-items: center;
`;

const HelperText = styled.div`
  font-size: ${fontSizes.small};
  color: ${colors.primaryGreyMediumDark};
  margin-top: 4px;
`;

export default function PayoutList({
  payouts,
  title,
  subTitle,
  customer,
}: {
  payouts: Array<Payout>;
  title: string;
  subTitle: ReactElement;
  customer?: Customer;
}) {
  return (
    <PayoutGroup>
      <SubTitleText>{title}</SubTitleText>
      <NormalText>{subTitle}</NormalText>
      <DividedCard
        elements={payouts.map((payout: Payout, i: number) => {
          return (
            <PayoutListItemContainer key={i}>
              <PayoutListItem>
                <IconBackground>
                  <PayoutIcon />
                </IconBackground>
                <div>
                  <Description>{payout.description}</Description>
                  {payout.issuanceStrategy.autoIssueOn &&
                    customer?.featureFlags?.showManualIssuanceAutoIssuanceDate?.enabled && (
                      <HelperText>
                        Scheduled to issue on -{' '}
                        {dayjs(payout.issuanceStrategy.autoIssueOn)
                          .tz(dayjs.tz.guess())
                          .format('MM/DD/YYYY')}
                      </HelperText>
                    )}
                </div>
              </PayoutListItem>
              <PayoutAmount>{formatCentsAsCurrency(payout.amountCents)}</PayoutAmount>
            </PayoutListItemContainer>
          );
        })}
      />
    </PayoutGroup>
  );
}
