import { Button } from '@checkrx/pay-component-library';
import { ValidationResult } from 'joi';
import { FC, useState } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { colors } from '../styles';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  width: 100%;
  max-width: 400px;
  height: 100%;
  background: ${colors.primaryWhite};
  opacity: 95%;
  padding: 12px;
  z-index: 1000;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 16px;
`;

const Title = styled.div`
  width: 100%;
  font-size: 22px;
  color: ${colors.primaryBlue};
  font-weight: bold;
`;

const ValidationList = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;
  scrollbar-width: thin;
`;

const ValidationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ValidationKeyText = styled.span`
  color: ${colors.primaryGreyMediumDark};
`;

const ValidationError = styled.span`
  color: ${colors.accentRed};
`;

const ValidationSetTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: ${colors.primaryBlue};
  margin-top: 12px;
  margin-bottom: 8px;
`;

export const ValidationDebugger: FC<{
  show: boolean;
  onClose: () => void;
  validations: Record<string, ValidationResult>;
}> = ({ show, onClose, validations }) => {
  if (!show) return null;

  return createPortal(
    <Container>
      <Header>
        <Title>Validation</Title>
        <Button text="Close" onClick={onClose} colorVariant="light" />
      </Header>
      <ValidationList>
        {Object.keys(validations).map(
          (validationSet) =>
            (validations?.[validationSet]?.error?.details?.length ?? 0) > 0 && (
              <div key={validationSet}>
                <ValidationSetTitle>{validationSet}</ValidationSetTitle>
                {validations[validationSet]?.error?.details.map((validation) => (
                  <ValidationContainer key={`${validationSet}-${validation.path.join('.')}`}>
                    <ValidationKeyText>{validation.path.join('.')}</ValidationKeyText>
                    <ValidationError>{validation.message}</ValidationError>
                  </ValidationContainer>
                ))}
              </div>
            )
        )}
      </ValidationList>
    </Container>,
    document.body
  );
};

export const useValidationDebugger = () => {
  const [show, setShow] = useState(false);
  const [_, setTapCount] = useState(0);

  const handleTaps = () => {
    const startTaps = () => {
      setTapCount((prevCount) => {
        const newCount = prevCount + 1;

        if (newCount === 1) {
          setTimeout(() => {
            setTapCount(0);
          }, 4000);
        }

        if (newCount >= 10) {
          setShow(true);
          setTapCount(0);
        }

        return newCount;
      });
    };

    return {
      onMouseDown: startTaps,
      onTouchStart: startTaps,
    };
  };

  return { show, setShow, handleTaps };
};
