import { LoadingScreen } from '@/app/wrappers/Containers';
import {
  ControlsContainer,
  Denominator,
  MiddleRow,
  Numerator,
  StyledBalance,
  Text,
  TransferIconHiddenWrapper,
  TransferIconWrapper,
  ViewContainer,
  WithdrawalLimitsWrapper,
} from '@/pages/dashboard/withdraw/WithdrawPage.styled';
import ErrorPage from '@/pages/errors/ErrorPage';
import { useBankingAccount } from '@/services/useBankingAccount';
import { useTransactions } from '@/services/useTransactions';
import { useWorkerProfile, useWorkerProfileIsSampleAccount } from '@/services/useWorkerProfile';
import { ReactComponent as TransferIcon } from '@/shared/assets/transfer-arrows.svg';
import DividedCard from '@/shared/components/DividedCard';
import FillBar from '@/shared/components/FillBar';
import { toast } from '@/shared/components/Toaster/Toaster';
import TransactionList from '@/shared/components/TransactionList';
import { formatCentsAsCurrency } from '@/shared/helpers';
import { Transaction, TransactionDirection, TransactionType } from '@/shared/types';
import { getWorkerAstraConfig } from '@/shared/withdrawals';
import { Button, Spinner } from '@checkrx/pay-component-library';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';

export default function WithdrawPage() {
  const navigate = useNavigate();

  const { data: allTransactions, isLoading: isLoadingTransactions } = useTransactions();
  const { data: bankingData, isLoading: isBankingLoading } = useBankingAccount();
  const { data: workerProfile, isLoading: isWorkerProfileLoading } = useWorkerProfile();
  const { data: isSampleAccount } = useWorkerProfileIsSampleAccount();

  const [timeFrame, setTimeFrame] = useState<'Daily' | 'Monthly'>('Daily');

  const dailyWithdrawalLimit = bankingData?.limits?.ach.limits.dailyCredit;
  const monthlyWithdrawalLimit = bankingData?.limits?.ach.limits.monthlyCredit;
  const dailyWithdrawalTotal = bankingData?.limits?.ach.totalsDaily.credits;
  const monthlyWithdrawalTotal = bankingData?.limits?.ach.totalsMonthly.credits;

  const dailyAtmWithdrawalTotal = bankingData?.limits?.card.totalsDaily.withdrawals;
  const dailyAtmWithdrawalLimit = bankingData?.limits?.card.limits.dailyWithdrawal;

  const withdrawalLimit = timeFrame === 'Daily' ? dailyWithdrawalLimit : monthlyWithdrawalLimit;
  const withdrawalTotal = timeFrame === 'Daily' ? dailyWithdrawalTotal : monthlyWithdrawalTotal;

  const amountString = formatCentsAsCurrency(bankingData?.available, { skipCurrencySymbol: true });

  const withdrawalTransactions: Transaction[] = (allTransactions || []).filter(
    (transaction: Transaction) => {
      return (
        transaction.transactionType === TransactionType.Ach &&
        transaction.direction === TransactionDirection.Debit
      );
    }
  );

  const toggleTimeFrame = () => {
    if (timeFrame === 'Monthly') {
      setTimeFrame('Daily');
    } else {
      setTimeFrame('Monthly');
    }
  };

  const swipeHandlers = useSwipeable({
    onSwipedRight: () => {
      navigate('/dashboard/card');
    },
    onSwipedLeft: () => {
      navigate('/dashboard/settings');
    },
  });

  if (isLoadingTransactions || isBankingLoading || isWorkerProfileLoading) {
    return (
      <LoadingScreen>
        <Spinner />
        <Text>Loading your withdrawals...</Text>
      </LoadingScreen>
    );
  }

  if (!workerProfile) {
    return <ErrorPage />;
  }

  const { perTransferLimit: instantTransferLimit } = getWorkerAstraConfig(workerProfile.profile);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const toastIfSampleAccount = (actionFn: (e: any) => void) =>
    isSampleAccount
      ? () =>
          toast({
            message: 'Start receiving payouts to unlock this functionality!',
            type: 'info',
            duration: 5000,
          })
      : actionFn;

  return (
    <ViewContainer {...swipeHandlers}>
      <StyledBalance amount={amountString} hold={bankingData?.hold} />

      <DividedCard
        elements={[
          <ControlsContainer key="withdraw-controls">
            <Button
              width="100%"
              text="Withdraw"
              sizeVariant="small"
              colorVariant="brand"
              onClick={toastIfSampleAccount(() => navigate('/withdraw'))}
            />
            <MiddleRow>
              <TransferIconWrapper onClick={toggleTimeFrame}>
                <TransferIcon />
              </TransferIconWrapper>
              <Text>{timeFrame} withdrawal limit</Text>
              <WithdrawalLimitsWrapper>
                <Text>
                  <Numerator>{formatCentsAsCurrency(withdrawalTotal)}</Numerator>
                  {' / '}
                  <Denominator>{formatCentsAsCurrency(withdrawalLimit)} </Denominator>
                </Text>
              </WithdrawalLimitsWrapper>
            </MiddleRow>

            <MiddleRow>
              <TransferIconHiddenWrapper>
                <TransferIcon />
              </TransferIconHiddenWrapper>
              <Text>Daily ATM withdrawal limit</Text>
              <WithdrawalLimitsWrapper>
                <Text>
                  <Numerator>{formatCentsAsCurrency(dailyAtmWithdrawalTotal)}</Numerator>
                  {' / '}
                  <Denominator>{formatCentsAsCurrency(dailyAtmWithdrawalLimit)}</Denominator>
                </Text>
              </WithdrawalLimitsWrapper>
            </MiddleRow>
            <MiddleRow>
              <TransferIconHiddenWrapper>
                <TransferIcon />
              </TransferIconHiddenWrapper>
              <Text>Per instant withdrawal limit</Text>
              <WithdrawalLimitsWrapper>
                <Text>
                  <Numerator>{formatCentsAsCurrency(instantTransferLimit)}</Numerator>
                </Text>
              </WithdrawalLimitsWrapper>
            </MiddleRow>
            <FillBar
              lightFillPercent={100}
              brightFillPercent={Math.round((withdrawalTotal || 0) / (withdrawalLimit || 1))}
            />
          </ControlsContainer>,
        ]}
      />

      <TransactionList
        transactions={withdrawalTransactions.slice(0, 3)}
        title="Recent withdrawals"
      />

      <Button
        width="100%"
        colorVariant="dark"
        sizeVariant="small"
        text="View all withdrawals"
        onClick={toastIfSampleAccount(() => {
          navigate('/transactions/withdrawals');
        })}
      />
    </ViewContainer>
  );
}
