import { Icon, TextInput } from '@checkrx/pay-component-library';
import { TextInputProps } from '@checkrx/pay-component-library/dist/TextInput/TextInput.types';
import { useEffect, useRef, useState } from 'react';
import { Container, EmptyOption, Option, OptionLabel } from './DropdownInput.styled';
import { DropdownInputOption, DropdownInputProps } from './DropdownInput.types';

type Props = Omit<TextInputProps, keyof DropdownInputProps> &
  DropdownInputProps & {
    onClickOutside?: () => void;
    searchValue: string;
    onSearchChange: (search: string) => void;
  };

function DropdownInput({
  options,
  onSelect,
  value: _value,
  closeOnChange = true,
  onClickOutside = () => undefined,
  searchValue,
  onSearchChange,
  ...rest
}: Props) {
  const [value, setValue] = useState(_value);
  useEffect(() => {
    setValue(_value);
  }, [_value]);

  const [menuOpen, setMenuOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const currentOption = options.find((opt) => opt.value === value) || { label: '', value: '' };

  const clearSelection = () => {
    onSelect('');
    setValue('');
    onSearchChange('');
  };

  const handleClickOutside = () => {
    onClickOutside();
    onSearchChange(currentOption.label);
    onSelect(currentOption.value);
    setMenuOpen(false);
  };

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        handleClickOutside();
      }
    };

    document.addEventListener('mousedown', handleClick);
    return () => document.removeEventListener('mousedown', handleClick);
  }, [onClickOutside, currentOption]);

  const handleSelect = (opt: DropdownInputOption) => {
    if (closeOnChange) {
      setMenuOpen(false);
    }

    if (opt.value === value) {
      clearSelection();
      return;
    }

    onSelect(opt.value);
    setValue(opt.value);
    onSearchChange(opt.label);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && options.length > 0 && options[0]) {
      handleSelect(options[0]);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    onSearchChange(newValue);
    if (newValue === '') {
      clearSelection();
    }
    setMenuOpen(true);
  };

  const handleInputFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setMenuOpen(true);
    e.target.setSelectionRange(0, e.target.value.length);
  };

  const { onChange: _, ...restProps } = rest;

  return (
    <div style={{ position: 'relative' }} ref={containerRef}>
      <div ref={inputRef}>
        <TextInput
          value={searchValue || currentOption.label}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          onKeyDown={handleKeyDown}
          errorText="Please search and select an address"
          {...restProps}
        />
      </div>
      {menuOpen && options.length > 0 && (
        <Container
          style={{
            width: inputRef.current?.getBoundingClientRect().width,
            position: 'absolute',
            top: 'calc(100% + 8px)',
            left: 0,
            zIndex: 1000,
            maxHeight: '300px',
            overflowY: 'auto',
          }}
        >
          {options.map((option) => (
            <Option key={option.value} onClick={() => handleSelect(option)}>
              <OptionLabel>{option.label}</OptionLabel>
              {currentOption.value === option.value && <Icon name="check" color="success" />}
            </Option>
          ))}
          {options.length === 0 && <EmptyOption>No addresses found</EmptyOption>}
        </Container>
      )}
    </div>
  );
}

export default DropdownInput;
