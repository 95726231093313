/**
 * TransactionDetail – show the details of a single transaction for a page.
 *
 * The Transaction Views don't fetch transaction data – instead the TransactionsPage
 * which renders them does so that we don't re-pull data every time we navigate
 * to a sub-page.
 */
import TransactionSymbol from '@/shared/components/TransactionSymbol';
import { dateFormatter, formatCentsAsCurrency, WITHDRAWAL_PAGE } from '@/shared/helpers';
import { Button } from '@checkrx/pay-component-library';
import { useNavigate, useParams } from 'react-router-dom';

import { useTransactions } from '@/services/useTransactions';
import { ActionPrompt } from '@/shared/components/InAppMessages/MessageContent';
import { SmallMediumText } from '@/shared/components/Text.styled';
import { useExternalLinkPoster } from '@/shared/hooks/useExternalLinkPoster';
import { colors, fontSizes, fontWeights } from '@/shared/styles';
import { Transaction, TransactionStatus } from '@/shared/types';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { hideBalance } from './utils';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  width: 100%;
  height: 100%;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

const TransactionInfo = styled.div`
  margin-left: 16px;
`;

const Amount = styled.div`
  font-size: ${fontSizes.jumbo};
  font-weight: ${fontWeights.bold};
  color: ${(p) => p.theme.colors.text.accent};
`;

const Description = styled.div`
  font-size: ${fontSizes.medium};
  color: ${colors.primaryGreyMediumDark};
  margin-top: 4px;
`;

const DetailSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;
`;

const DetailRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DetailLabel = styled.div`
  font-size: ${fontSizes.medium};
  color: ${colors.primaryGreyMediumDark};
`;

const DetailValue = styled.div`
  font-size: ${fontSizes.medium};
  color: ${colors.primaryBlue};
  font-weight: ${fontWeights.bold};
`;

const FeeBadge = styled.div`
  background-color: #ffd700;
  color: black;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: ${fontSizes.small};
  font-weight: ${fontWeights.bold};
`;

const ClearingTimeSection = styled.div`
  background-color: ${colors.primaryGreyLight};
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 24px;
`;

export default function TransactionDetailPage() {
  const { transactionId } = useParams();
  const navigate = useNavigate();

  const sendExternalLink = useExternalLinkPoster({
    link: WITHDRAWAL_PAGE,
  });

  const { data: transactions = [] } = useTransactions();

  const transaction = transactions.find((t: Transaction) => t.transactionId === transactionId);
  if (!transaction) {
    return <></>;
  }

  const payoutMethodFee = Number(transaction.metadata?.payoutMethodFeeCents ?? 0);

  const amount = formatCentsAsCurrency(transaction.amount - payoutMethodFee);
  const {
    createdAt,
    expectedCompletionDate,
    transactionType,
    summary,
    direction,
    description,
    metadata,
    transactionStatus,
  } = transaction;

  const status =
    transactionStatus === TransactionStatus.Authorized
      ? 'Pending'
      : transactionStatus === TransactionStatus.Sent
        ? 'Completed'
        : transactionStatus;

  const expectedCompletionDateMessage =
    status === TransactionStatus.Pending
      ? expectedCompletionDate
        ? dayjs(expectedCompletionDate).format('MMM D, YYYY')
        : dayjs().isAfter(dayjs(expectedCompletionDate))
          ? 'Any time today'
          : null
      : null;

  return (
    <Container>
      <Header>
        <TransactionSymbol size="60px" type={transactionType} direction={direction} />
        <TransactionInfo>
          <Amount>{amount}</Amount>
          <Description>{metadata?.annotation || description || summary}</Description>
        </TransactionInfo>
      </Header>

      <DetailSection>
        <DetailRow>
          <DetailLabel>Date</DetailLabel>
          <DetailValue>{dateFormatter(createdAt)}</DetailValue>
        </DetailRow>
        <DetailRow>
          <DetailLabel>Status</DetailLabel>
          <DetailValue>{status}</DetailValue>
        </DetailRow>
        {transaction?.balance && !hideBalance(transaction) && (
          <DetailRow>
            <DetailLabel>Available Balance</DetailLabel>
            <DetailValue>{formatCentsAsCurrency(transaction.balance)}</DetailValue>
          </DetailRow>
        )}
        {payoutMethodFee > 0 && (
          <DetailRow>
            <DetailLabel>Fee</DetailLabel>
            <FeeBadge>{formatCentsAsCurrency(payoutMethodFee)} fee</FeeBadge>
          </DetailRow>
        )}
      </DetailSection>

      {expectedCompletionDateMessage && (
        <ClearingTimeSection>
          <DetailLabel>Approximate Clearing Time</DetailLabel>
          <DetailValue>{expectedCompletionDateMessage}</DetailValue>
          <SmallMediumText>
            Your bank may take additional time to release funds.{' '}
            <ActionPrompt onClick={sendExternalLink}>Learn more about it here</ActionPrompt>.
          </SmallMediumText>
        </ClearingTimeSection>
      )}

      <Button
        icon="help-circle"
        width="100%"
        text="Contact support"
        colorVariant="light"
        sizeVariant="small"
        onClick={() => navigate('/support')}
      />
    </Container>
  );
}
