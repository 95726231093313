import { NormalText, TitleText } from '@/shared/components/Text.styled';
import { fontSizes, fontWeights } from '@/shared/styles';
import { Button } from '@checkrx/pay-component-library';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { Address } from './AddressForm';
import { PersonalDetails } from './PersonalDetailsForm';

const ReviewModalContent = styled.div`
  max-width: 500px;
  margin: 0 auto;
  flex-direction: column;
  display: flex;
  gap: 12px;
`;

const ReviewSection = styled.div``;

const ReviewHeader = styled.div`
  margin-bottom: 8px;
  font-weight: ${fontWeights.bold};
  font-size: ${fontSizes.medium};
  color: ${(p) => p.theme.colors.text.accent};
`;

const ReviewRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const ReviewLabel = styled.div`
  font-weight: ${fontWeights.light};
  font-size: ${fontSizes.medium};
  color: ${(p) => p.theme.colors.text.secondary};
`;

const ReviewValue = styled.div`
  margin-bottom: 4px;
  font-weight: ${fontWeights.light};
  font-size: ${fontSizes.medium};
  color: ${(p) => p.theme.colors.text.primary};
`;

const ReviewButtons = styled.div`
  display: flex;
  gap: 12px;
  justify-content: space-between;
  margin-top: 24px;
  width: 100%;
`;

type Props = {
  personalDetails: PersonalDetails;
  hasPreferredName: boolean;
  preferredName: string;
  contactPhone: string;
  physicalAddress: Address;
  mailingAddress: Address;
  hasMailingAddress: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

export const ReviewModal = ({
  personalDetails,
  hasPreferredName,
  preferredName,
  contactPhone,
  physicalAddress,
  mailingAddress,
  hasMailingAddress,
  onClose,
  onConfirm,
}: Props) => {
  return (
    <ReviewModalContent>
      <TitleText>Please Review Your Information</TitleText>

      <ReviewSection>
        <ReviewHeader>Personal Details</ReviewHeader>
        <ReviewRow>
          <ReviewLabel>First Name:</ReviewLabel>
          <ReviewValue>{personalDetails.firstName}</ReviewValue>
        </ReviewRow>
        {personalDetails.middleName && (
          <ReviewRow>
            <ReviewLabel>Middle Name:</ReviewLabel>
            <ReviewValue>{personalDetails.middleName}</ReviewValue>
          </ReviewRow>
        )}
        <ReviewRow>
          <ReviewLabel>Last Name:</ReviewLabel>
          <ReviewValue>{personalDetails.lastName}</ReviewValue>
        </ReviewRow>
        {hasPreferredName && (
          <ReviewRow>
            <ReviewLabel>Preferred Name:</ReviewLabel>
            <ReviewValue>{preferredName}</ReviewValue>
          </ReviewRow>
        )}
        <ReviewRow>
          <ReviewLabel>Date of Birth:</ReviewLabel>
          <ReviewValue>{dayjs(personalDetails.dob).format('MM/DD/YYYY')}</ReviewValue>
        </ReviewRow>
        <ReviewRow>
          <ReviewLabel>Email:</ReviewLabel>
          <ReviewValue>{personalDetails.email}</ReviewValue>
        </ReviewRow>
        <ReviewRow>
          <ReviewLabel>Phone:</ReviewLabel>
          <ReviewValue>{contactPhone}</ReviewValue>
        </ReviewRow>
      </ReviewSection>

      <ReviewSection>
        <ReviewHeader>Physical Address</ReviewHeader>
        <ReviewRow>
          <ReviewValue>{physicalAddress.street}</ReviewValue>
        </ReviewRow>
        {physicalAddress.street2 && (
          <ReviewRow>
            <ReviewValue>{physicalAddress.street2}</ReviewValue>
          </ReviewRow>
        )}
        <ReviewRow>
          <ReviewValue>
            {physicalAddress.city}, {physicalAddress.state} {physicalAddress.postalCode}
          </ReviewValue>
        </ReviewRow>
      </ReviewSection>

      {hasMailingAddress && (
        <ReviewSection>
          <ReviewHeader>Mailing Address</ReviewHeader>
          <ReviewRow>
            <ReviewValue>{mailingAddress.street}</ReviewValue>
          </ReviewRow>
          {mailingAddress.street2 && (
            <ReviewRow>
              <ReviewValue>{mailingAddress.street2}</ReviewValue>
            </ReviewRow>
          )}
          <ReviewRow>
            <ReviewValue>
              {mailingAddress.city}, {mailingAddress.state} {mailingAddress.postalCode}
            </ReviewValue>
          </ReviewRow>
        </ReviewSection>
      )}
      <NormalText style={{ marginTop: '8px', textAlign: 'center' }}>
        By clicking Confirm, you acknowledge that all information provided is accurate and has been
        reviewed carefully.
      </NormalText>

      <ReviewButtons>
        <Button text="Go Back" onClick={onClose} width="45%" />
        <Button text="Confirm" colorVariant="brand" onClick={onConfirm} width="45%" />
      </ReviewButtons>
    </ReviewModalContent>
  );
};
