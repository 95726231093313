import { useWorkerProfileIsVerified } from '@/services/useWorkerProfile';
import { ReactComponent as LockedMoneyIcon } from '@/shared/assets/locked-money.svg';
import PayoutList from '@/shared/components/PayoutNotices/PayoutList';
import {
  NoticeButton,
  TitleContainer,
} from '@/shared/components/PayoutNotices/PayoutNotices.styled';
import { formatCentsAsCurrency } from '@/shared/helpers';
import { useSlideUpDrawer } from '@/shared/hooks/useSlideUpDrawer';
import { Payout, PayoutFailureReasons, PayoutMethod, PayoutStatus } from '@/shared/types';
import { useNavigate } from 'react-router-dom';
import { StyledLink } from '../Text.styled';

type Props = {
  payouts: Array<Payout>;
  directDepositPayoutMethod: string;
};

export default function InvalidDirectDepositPayoutDestinationNotice({
  payouts,
  directDepositPayoutMethod,
}: Props) {
  const { SlideUpDrawer, openDrawer } = useSlideUpDrawer();
  const navigate = useNavigate();

  // Set the necessary conditions for the payouts to be displayed
  let failureReason: PayoutFailureReasons;
  let failedDestination: string | undefined;
  let failedDirectDepositPayoutMethod: string | undefined;
  switch (directDepositPayoutMethod) {
    case PayoutMethod.ACHDirectDeposit:
      failureReason = PayoutFailureReasons.InvalidDirectDepositDestination;
      failedDestination = 'Bank Account';
      failedDirectDepositPayoutMethod = 'Direct Deposits';
      break;

    case PayoutMethod.PushToCardDirectDeposit:
      failureReason = PayoutFailureReasons.InvalidPushToCardDirectDepositPayoutDestination;
      failedDestination = 'Debit Card';
      failedDirectDepositPayoutMethod = 'Instant Direct Deposits';
      break;

    default:
      failedDestination = '';
      failedDirectDepositPayoutMethod = '';
  }

  const { data: isWorkerProfileVerified, isLoading: isWorkerProfileLoading } =
    useWorkerProfileIsVerified();
  const failedPayouts = payouts.filter(
    (payout) =>
      payout?.status === PayoutStatus.Failed && payout?.failureReasons?.includes(failureReason)
  );

  if (failedPayouts.length === 0 || isWorkerProfileLoading) {
    return null;
  }

  const totalAmount = failedPayouts.reduce((sum, payout) => sum + payout.amountCents, 0);
  const subTitle = (
    <>
      The following payouts failed because the {failedDestination} used for{' '}
      {failedDirectDepositPayoutMethod} did not work. Please{' '}
      {isWorkerProfileVerified ? (
        <StyledLink onClick={() => navigate('/manage-payout-methods')}>
          update the bank account
        </StyledLink>
      ) : (
        <>sign in and update the bank account</>
      )}{' '}
      or use a different payout method.
    </>
  );

  return (
    <>
      <NoticeButton type="button" onClick={openDrawer} error>
        <TitleContainer>
          <LockedMoneyIcon />
          <div>Payouts Failed - Update {failedDestination}</div>
        </TitleContainer>
        <div>{formatCentsAsCurrency(totalAmount)}</div>
      </NoticeButton>
      <SlideUpDrawer>
        <PayoutList payouts={failedPayouts} title="Failed Payouts" subTitle={subTitle} />
      </SlideUpDrawer>
    </>
  );
}
