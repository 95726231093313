/**
 * PII Update Page – currently just links out to zendesk.
 * Eventually, it'll have Self-service
 */
import { TabWrapper } from '@/app/wrappers/Containers';
import {
  DataContent,
  DataLabel,
  Group,
  PiiRow,
} from '@/pages/onboarding/shared/confirm-pages.styled';
import { useWorkerProfile } from '@/services/useWorkerProfile';
import { UpdateField, useRequestWorkerPersonalInfoUpdate } from '@/services/useWorkerSupport';
import { trackAmplitudeEvent } from '@/shared/analytics';
import DividedCard from '@/shared/components/DividedCard';
import PageNavBar from '@/shared/components/PageNavBar';
import { Bold, NormalText, SmallText, TitleText } from '@/shared/components/Text.styled';
import { toast } from '@/shared/components/Toaster/Toaster';
import { Button, Modal, TextInput } from '@checkrx/pay-component-library';
import { useState } from 'react';
import styled from 'styled-components';
import {
  BankAccountAndPersonalInformationView,
  SectionHeader,
  UpdateInfoInstructionWrapper,
} from './SupportPage.styled';

const FormField = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
  align-items: flex-start;
  justify-content: space-between;
  min-width: 100%;
`;

const FormLabel = styled(DataLabel)`
  margin-bottom: 4px;
  width: 80px;
  word-wrap: break-word;
  text-align: left;
`;

export default function PersonalInfoPage() {
  const { data: workerProfile } = useWorkerProfile();
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [updateValues, setUpdateValues] = useState<Record<UpdateField['updateKey'], string>>({
    firstName: '',
    middleName: '',
    lastName: '',
    phone: '',
    email: '',
    ssn: '',
    accountAddress: '',
    shippingAddress: '',
  });

  const { mutate: requestWorkerPersonalInfoUpdate } = useRequestWorkerPersonalInfoUpdate();

  const {
    contactEmail,
    contactPhone,
    contactAddress,
    mailingAddress,
    checkrCorePII,
    preferredName,
  } = workerProfile?.profile || {};
  const { firstName, middleName, lastName, dateOfBirth, taxpayerIdentificationNumber } =
    checkrCorePII || {};
  const { street, street2, city, state, postalCode } = contactAddress || {};
  const {
    street: mailingStreet,
    street2: mailingStreet2,
    city: mailingCity,
    state: mailingState,
    postalCode: mailingPostalCode,
  } = mailingAddress || {};

  const handleOnUpdateSubmitButton = () => {
    trackAmplitudeEvent('Earner Requested Personal Info Update');
    setShowUpdateModal(false);

    const updates = Object.entries(updateValues)
      .filter(([_, value]) => value.trim())
      .map(([key, value]) => ({
        updateKey: key,
        updateValue: value.trim(),
      })) as UpdateField[];

    requestWorkerPersonalInfoUpdate(updates, {
      onSuccess: () => {
        toast({
          type: 'success',
          message: 'Update request submitted for review! Changes will apply shortly.',
          duration: 5000,
        });
      },
      onError: () => {
        toast({
          type: 'error',
          message: 'Failed to submit update request. Please try again.',
          duration: 5000,
        });
      },
    });

    setUpdateValues({
      firstName: '',
      middleName: '',
      lastName: '',
      phone: '',
      email: '',
      ssn: '',
      accountAddress: '',
      shippingAddress: '',
    });
  };

  const handleInputChange = (field: string, value: string) => {
    setUpdateValues((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  return (
    <TabWrapper>
      <PageNavBar title="Your Information" />
      <BankAccountAndPersonalInformationView>
        <DividedCard
          elements={[
            <Group key="profile-contact-info">
              <SectionHeader>
                <TitleText> Personal Information </TitleText>
              </SectionHeader>
              <PiiRow>
                <DataLabel>Full Legal Name:</DataLabel>
                <DataContent>
                  {firstName} {middleName} {lastName}
                </DataContent>
              </PiiRow>
              {preferredName ? (
                <PiiRow>
                  <DataLabel>Preferred Name:</DataLabel>
                  <DataContent>{preferredName}</DataContent>
                </PiiRow>
              ) : null}
              <PiiRow>
                <DataLabel>Date Of Birth:</DataLabel>
                <DataContent>{dateOfBirth}</DataContent>
              </PiiRow>
              <PiiRow>
                <DataLabel>SSN (last 4):</DataLabel>
                <DataContent>{taxpayerIdentificationNumber}</DataContent>
              </PiiRow>
              <PiiRow>
                <DataLabel>Email:</DataLabel>
                <DataContent>{contactEmail}</DataContent>
              </PiiRow>
              <PiiRow>
                <DataLabel>Phone:</DataLabel>
                <DataContent>{contactPhone}</DataContent>
              </PiiRow>
            </Group>,
            <Group key="profile-address-info">
              <SectionHeader>
                <TitleText> Account Address </TitleText>
              </SectionHeader>
              <PiiRow>
                <DataLabel>Address:</DataLabel>
                <DataContent>
                  {street} {street2 || ''}
                </DataContent>
              </PiiRow>
              <PiiRow>
                <DataLabel>City:</DataLabel>
                <DataContent>{city}</DataContent>
              </PiiRow>
              <PiiRow>
                <DataLabel>State:</DataLabel>
                <DataContent>{state}</DataContent>
              </PiiRow>
              <PiiRow>
                <DataLabel>ZIP:</DataLabel>
                <DataContent>{postalCode}</DataContent>
              </PiiRow>
            </Group>,
            <Group key="profile-mailing-address-info">
              <SectionHeader>
                <TitleText> Mailing Address </TitleText>
              </SectionHeader>
              {mailingAddress ? (
                <>
                  <PiiRow>
                    <DataLabel>Address:</DataLabel>
                    <DataContent>
                      {mailingStreet} {mailingStreet2 || ''}
                    </DataContent>
                  </PiiRow>
                  <PiiRow>
                    <DataLabel>City:</DataLabel>
                    <DataContent>{mailingCity}</DataContent>
                  </PiiRow>
                  <PiiRow>
                    <DataLabel>State:</DataLabel>
                    <DataContent>{mailingState}</DataContent>
                  </PiiRow>
                  <PiiRow>
                    <DataLabel>ZIP:</DataLabel>
                    <DataContent>{mailingPostalCode}</DataContent>
                  </PiiRow>
                </>
              ) : (
                <SectionHeader>
                  <NormalText>
                    <Bold>Same as account address </Bold>
                  </NormalText>
                </SectionHeader>
              )}
            </Group>,
          ]}
        />
      </BankAccountAndPersonalInformationView>

      <Button
        text="Request Profile Update"
        width="100%"
        colorVariant="accent"
        sizeVariant="big"
        onClick={() => setShowUpdateModal(true)}
      />

      <Modal
        isOpen={showUpdateModal}
        close={() => setShowUpdateModal(false)}
        headerText="Update your information"
        width="85%"
        height="60%"
        modalContent={
          <TabWrapper>
            <BankAccountAndPersonalInformationView>
              <UpdateInfoInstructionWrapper>
                <NormalText>
                  Enter new values for any information you would like to update. Leave fields blank
                  if no update is needed.
                </NormalText>
              </UpdateInfoInstructionWrapper>
              <FormField>
                <FormLabel>First Name</FormLabel>
                <TextInput
                  inputSize="small"
                  value={updateValues.firstName}
                  type="text"
                  placeholder="Enter new first name"
                  onChange={(e) => handleInputChange('firstName', e.target.value)}
                />
              </FormField>
              <FormField>
                <FormLabel>Middle Name</FormLabel>
                <TextInput
                  inputSize="small"
                  value={updateValues.middleName}
                  type="text"
                  placeholder="Enter new middle name"
                  onChange={(e) => handleInputChange('middleName', e.target.value)}
                />
              </FormField>
              <FormField>
                <FormLabel>Last Name</FormLabel>
                <TextInput
                  inputSize="small"
                  value={updateValues.lastName}
                  type="text"
                  placeholder="Enter new last name"
                  onChange={(e) => handleInputChange('lastName', e.target.value)}
                />
              </FormField>
              <FormField>
                <FormLabel>Phone</FormLabel>
                <TextInput
                  inputSize="small"
                  value={updateValues.phone}
                  type="text"
                  placeholder="Enter new phone"
                  onChange={(e) => handleInputChange('phone', e.target.value)}
                />
              </FormField>
              <FormField>
                <FormLabel>Email</FormLabel>
                <TextInput
                  inputSize="small"
                  value={updateValues.email}
                  type="text"
                  placeholder="Enter new email"
                  onChange={(e) => handleInputChange('email', e.target.value)}
                />
              </FormField>
              <FormField>
                <FormLabel>SSN</FormLabel>
                <TextInput
                  inputSize="small"
                  value={updateValues.ssn}
                  type="text"
                  placeholder="Enter new SSN"
                  onChange={(e) => handleInputChange('ssn', e.target.value)}
                />
              </FormField>
              <FormField>
                <FormLabel>Contact Address</FormLabel>
                <TextInput
                  inputSize="small"
                  value={updateValues.accountAddress}
                  type="text"
                  placeholder="Enter new account address"
                  onChange={(e) => handleInputChange('accountAddress', e.target.value)}
                />
              </FormField>
              <FormField>
                <FormLabel>Mailing Address</FormLabel>
                <TextInput
                  inputSize="small"
                  value={updateValues.shippingAddress}
                  type="text"
                  placeholder="Enter new mailing address"
                  onChange={(e) => handleInputChange('shippingAddress', e.target.value)}
                />
              </FormField>
              <SmallText>
                If entering an address, please enter the full address including street, city, state,
                and zip code.
              </SmallText>
              <Button
                text="Submit"
                width="100%"
                colorVariant="accent"
                sizeVariant="small"
                onClick={handleOnUpdateSubmitButton}
                disabled={!Object.values(updateValues).some((value) => value.trim().length >= 2)}
              />
            </BankAccountAndPersonalInformationView>
          </TabWrapper>
        }
      />
    </TabWrapper>
  );
}
