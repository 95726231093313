import { useEffectOnce } from '@/shared/hooks/use-effect-once';
import { colors, fontSizes, fontWeights } from '@/shared/styles';
import * as Sentry from '@sentry/react';
import { loadVGSCollect } from '@vgs/collect-js';
import { useState } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type VGSAny = any;

export type VGSCollect = {
  create: (r: unknown) => unknown;
  init: (callback: (state: Record<string, VGSAny>) => void) => VGSCollectForm;
  load: (t: unknown) => unknown;
  logLevel: (t: unknown) => unknown;
  modules: unknown[];
};

export type VGSCollectForm = {
  field: (
    selector: string,
    options: {
      type: string;
      name: string;
      placeholder: string;
      validations: (string | object)[];
      css: Record<string, unknown>;
      maxLength?: number;
    }
  ) => {
    replacePattern: (pattern: string) => void;
  };
  submit: (
    url: string,
    options: unknown,
    callback?: (status: number, data: Record<string, VGSAny>) => void
  ) => void;
  reset: () => void;
};

export const fieldCss = {
  width: 'calc(100vw - 38px)',
  margin: 4,
  height: 40,
  padding: '4px 16px',
  'border-radius': '10px',
  background: colors.primaryGreyLight,
  color: colors.primaryBlue,
  'font-weight': fontWeights.bold,
  border: 'none',
  outline: 'none',
  'font-size': '16px', // Prevents zoom on iOS
  '-webkit-text-size-adjust': '100%', // Prevents zoom on iOS
  'text-size-adjust': '100%', // Prevents zoom on iOS
  '&:focus': {
    outline: `1px solid ${colors.primaryBlue}`,
  },
  '&.invalid.touched': {
    outline: `1px solid ${colors.accentRed}`,
  },
  '::placeholder': {
    color: colors.primaryGreyMediumDark,
    'font-weight': fontWeights.light,
    'font-size': fontSizes.medium,
  },
};

export const useVGSForm = ({
  initForm,
  submitUrl,
  submitOptions,
  onSettled = undefined,
}: {
  initForm: (vgs_collect: VGSCollectForm) => void;
  submitUrl: string;
  submitOptions: unknown;
  onSettled?: (status: number, data: Record<string, VGSAny>) => void;
}) => {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState<VGSCollectForm | null>(null);
  const [formState, setFormState] = useState<Record<string, VGSAny>>({});

  useEffectOnce(() => {
    const loadForm = async () => {
      try {
        const vgs_collect: VGSCollect = (await loadVGSCollect({
          vaultId: process.env.REACT_APP_UNIT_VGS_VAULT_ID ?? '',
          environment: process.env.REACT_APP_VGS_ENVIRONMENT === 'sandbox' ? 'sandbox' : 'live',
          version: '2.18.0',
        })) as VGSCollect;
        const newForm = vgs_collect.init((state: Record<string, VGSAny>) => setFormState(state));
        initForm(newForm);
        setForm(newForm);
      } catch (e) {
        Sentry.captureException(e);
      }
    };

    loadForm();
  });

  const handleOnSettled = (status: number, data: Record<string, VGSAny>) => {
    onSettled?.(status, data);
    setLoading(false);
    if (status >= 200 && status < 300) {
      form?.reset?.();
    } else {
      Sentry.captureException(data);
    }
  };

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    if (form) {
      form.submit(submitUrl, submitOptions, handleOnSettled);
    }
  };

  return { form, formState, handleFormSubmit, loading };
};
