import { colors } from '@/shared/styles';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  height: fit-content;
  z-index: 50;
  max-height: 288px;
  overflow: hidden;
  overflow-y: auto;
  border: 1px solid ${colors.primaryGreyLight};
  position: absolute;
  top: calc(100% + 16px);
  border-radius: 6px;
  background-color: ${colors.trueWhite};
  padding: 8px 0;
`;

export const Option = styled.div<{ key: string; onClick: () => void }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 0 8px;
  width: 100%;
  cursor: pointer;
  &:hover {
    background-color: ${colors.primaryGreyLight};
  }
  height: 28px;
`;

export const OptionLabel = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 90%;
`;

export const EmptyOption = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 32px;
`;

const Circle = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
`;

export const Checked = styled(Circle)`
  background: ${(p) => p.theme.colors.buttons.accent};

  display: flex;
  align-items: center;
  justify-content: center;
`;
