import { useRequestWorkerZDAuthJWT } from '@/services/useWorkerSupport';
import { useEffect, useState } from 'react';
import { trackAmplitudeEvent } from '../analytics';

type isOpenValue = boolean;
type SetIsOpenFn = (value: boolean) => void;

const useZendeskChat = (): [isOpenValue, SetIsOpenFn] => {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const zE = (window as any).zE;
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const {
    mutate: requestJWT,
    data: jwt,
    isSuccess: requestJWTSuccess,
  } = useRequestWorkerZDAuthJWT();

  useEffect(() => {
    if (!zE) {
      return;
    }

    const showAndCloseMessenger = () => {
      zE('messenger', 'show');
      zE('messenger', 'open');
      trackAmplitudeEvent('Chat Support Viewed');

      zE('messenger:on', 'close', () => {
        setIsOpen(false);
      });
    }

    // Request the JWT we can use to auth this earner
    requestJWT();

    if (isOpen) {
      if (requestJWTSuccess) {
        zE('messenger', 'loginUser', (callback: (jwt: string) => void) => {
          callback(jwt);
        });
        showAndCloseMessenger()
      } else {
        showAndCloseMessenger()
      }
    } else {
      zE('messenger', 'close');
      zE('messenger', 'hide');
    }

    return () => {
      zE('messenger', 'close');
      zE('messenger', 'hide');
    };
  }, [zE, isOpen]);

  return [isOpen, setIsOpen];
};

export default useZendeskChat;
