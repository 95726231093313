import AstraCardApprovalNotice from '@/pages/withdrawal-flow/AstraStatuses';
import { useAddPayoutMethod } from '@/services/usePayoutMethod';
import { useCustomerFromProfile } from '@/services/useWorkerProfile';
import { ReactComponent as Zap } from '@/shared/assets/zap.svg';
import { colors } from '@/shared/styles';
import {
  AstraCard,
  AstraCardStatuses,
  DEFAULT_INSTANT_DIRECT_DEPOSIT_FEE_RATE,
  PayoutMethod,
  WorkerProfile,
} from '@/shared/types';
import { Button } from '@checkrx/pay-component-library';
import { FC, useState, useEffect } from 'react';
import { ExternalAccountLink } from '../../ExternalAccounts';
import { ErrorText, MediumWarningText } from '../../Text.styled';
import { PaymentMethodBadgeT, PaymentMethodChoice } from '../PaymentMethodChoice';
import { ConfirmationButtonsContainer } from '../../ButtonContainer.styled';
import { ShowRemoveConfirmationStates, RemoveInstantDirectDepositCard } from '../../RemoveInstantDirectDepositCard';

type Props = {
  astraError: boolean;
  workerProfile: WorkerProfile;
  removeCard: () => void;
  openAstra: () => void;
  card: AstraCard | undefined;
};

export const InstantDirectDepositChoice: FC<Props> = ({
  astraError,
  workerProfile,
  removeCard,
  openAstra,
  card,
}) => {
  const { data: customer } = useCustomerFromProfile();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showRemoveButton, setShowRemoveButton] = useState(false);
  const { mutateAsync: changePayoutMethod } = useAddPayoutMethod();
  const astraCardApproved = card?.status === AstraCardStatuses.Approved;
  const [showRemoveConfirmation, setShowRemoveConfirmation] =
    useState<ShowRemoveConfirmationStates>('hidden');

  useEffect(() => {
    if (astraCardApproved) {
      setShowRemoveButton(true);
    }
  }, [astraCardApproved])

  const handleConnectCard = () => {
    openAstra();
  };

  const handleChangeToInstant = () => {
    changePayoutMethod({ payoutMethod: PayoutMethod.PushToCardDirectDeposit });
    setShowConfirmation(false);
    setShowRemoveConfirmation('hidden')
  };
  const handleChangeToCheckrWallet = () => {
    changePayoutMethod({ payoutMethod: PayoutMethod.CheckrPayBankAccount });
  };

  const selectedPayoutMethod = workerProfile?.profile?.payoutMethod?.selected;

  const badges = [
    selectedPayoutMethod === PayoutMethod.PushToCardDirectDeposit ? 'active' : null,
  ].filter(Boolean) as PaymentMethodBadgeT[];

  const feesRate =
    customer?.payoutMethods?.pushToCardDirectDeposit?.customFeesRate ??
    DEFAULT_INSTANT_DIRECT_DEPOSIT_FEE_RATE;
  return (
    <PaymentMethodChoice
      title="Instant Direct Deposit"
      titleIcon={<Zap stroke={colors.textGrey} />}
      badges={badges}
      bulletPoints={[
        'Payouts complete in minutes',
        'Payouts sent to your bank account',
        <MediumWarningText key="fee">
          {(feesRate * 100).toFixed(1)}% transfer fee every time
          <div style={{ fontSize: 8 }}>
            For example, when you are paid $100, you will receive $
            {(100 - 100 * feesRate).toFixed(2)} in your debit card.
          </div>
        </MediumWarningText>,
      ]}
    >
      {card ? (
        <>
          <ExternalAccountLink
            key={card.id}
            type="card"
            accountNumber={card.lastFourDigits}
            bankName={card.cardCompany}
            ownerName={card.firstName + ' ' + card.lastName}
          />
          {!astraCardApproved && <AstraCardApprovalNotice astraCard={card} />}
        </>
      ) : null}
      {astraError && <ErrorText>Instant transfers are not available at this time</ErrorText>}
      
      {showRemoveButton ? (
        <RemoveInstantDirectDepositCard
          workerProfile={workerProfile}
          payoutMethodIddWarningText=
            'This will disable Instant Direct Deposit, and enable payouts via Checkr Wallet.'
          onToggleConfirmation={(shown) => shown && setShowConfirmation(false)}
          onRemoveConfirmed={() => {
            setShowRemoveButton(false);
            handleChangeToCheckrWallet();
            removeCard();
          }}
          disabled ={!!astraError}
          showRemoveConfirmation={showRemoveConfirmation}
          setShowRemoveConfirmation={setShowRemoveConfirmation}
        />
      ) : (
        <Button
          width="100%"
          text='Connect a card'
          sizeVariant="small"
          onClick={handleConnectCard}
        />
      )}
      {selectedPayoutMethod !== PayoutMethod.PushToCardDirectDeposit && card ? (
        <>
          {showConfirmation ? (
            <>
              <MediumWarningText>
                This will disable instant payouts via Checkr Wallet, and enable payouts via Instant
                Direct Deposit.
              </MediumWarningText>
              <ConfirmationButtonsContainer>
                <Button
                  width="45%"
                  text="Confirm"
                  colorVariant="dark"
                  sizeVariant="small"
                  onClick={handleChangeToInstant}
                />
                <Button
                  width="45%"
                  text="Cancel"
                  colorVariant="light"
                  sizeVariant="small"
                  onClick={() => {
                    setShowConfirmation(false)
                    setShowRemoveButton(!!card)
                  }}
                />
              </ConfirmationButtonsContainer>
            </>
          ) : (
            <Button
              width="100%"
              disabled={!astraCardApproved}
              text="Enable Instant Direct Deposit"
              colorVariant="light"
              sizeVariant="small"
              onClick={() => {
                setShowRemoveConfirmation('hidden');
                setShowConfirmation(true)
              }}
            />
          )}
        </>
      ) : null}
    </PaymentMethodChoice>
  );
};
