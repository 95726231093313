
import { FC } from 'react';
import { Button } from '@checkrx/pay-component-library';

import {
  PayoutMethod,
  WorkerProfile,
} from '@/shared/types';
import { ConfirmationButtonsContainer } from './ButtonContainer.styled';
import { MediumWarningText } from './Text.styled';
import { UnorderedList, ListItemWarning } from './List.styled';

type Props = {
  workerProfile: WorkerProfile;
  payoutMethodIddWarningText: string;
  onToggleConfirmation?: (confirmationShown: boolean) => void;
  onRemoveConfirmed: () => void;
  disabled?: boolean;
  initialState?: ShowRemoveConfirmationStates;
  setShowRemoveConfirmation: (confirmationShown: ShowRemoveConfirmationStates) => void;
  showRemoveConfirmation?: ShowRemoveConfirmationStates;
}

export type ShowRemoveConfirmationStates = 'hidden' | 'payoutMethodNotIDD' | 'payoutMethodIDD' 
  | 'calculated';

export const RemoveInstantDirectDepositCard: FC<Props> = ({
  workerProfile,
  payoutMethodIddWarningText,
  onRemoveConfirmed,
  setShowRemoveConfirmation,
  onToggleConfirmation = () => {return;},
  disabled = false,
  showRemoveConfirmation = 'hidden'
}) => {
  const handleRemoveCard = () => {
    const removeConfirmationVersion =
      workerProfile?.profile?.payoutMethod?.selected === PayoutMethod.PushToCardDirectDeposit ?
      'payoutMethodIDD' :
      'payoutMethodNotIDD';
    setShowRemoveConfirmation(removeConfirmationVersion);
    onToggleConfirmation(true);
  };

  return (
    <>
    {showRemoveConfirmation !== 'hidden' ? (
      <>
        <UnorderedList>
          {showRemoveConfirmation === 'payoutMethodIDD' && (
          <ListItemWarning><MediumWarningText>
            {payoutMethodIddWarningText}
            </MediumWarningText></ListItemWarning>
          )}
          <ListItemWarning><MediumWarningText>
            This method allows connecting only 3 distinct debit cards in each 6 month period.
            </MediumWarningText></ListItemWarning>
        </UnorderedList>
        <ConfirmationButtonsContainer>
          <Button
            width="45%"
            text="Confirm"
            colorVariant="dark"
            sizeVariant="small"
            onClick={() => {
              onRemoveConfirmed()
              setShowRemoveConfirmation('hidden')
            }}
          />
          <Button
            width="45%"
            text="Cancel"
            colorVariant="light"
            sizeVariant="small"
            onClick={() => {
              onToggleConfirmation(false);
              setShowRemoveConfirmation('hidden')
            }}
          />
        </ConfirmationButtonsContainer>
      </>
    ) : (
      <Button
        width="100%"
        text='Remove connected card'
        sizeVariant="small"
        onClick={handleRemoveCard}
        disabled={disabled}
      />
    )
  }
  </>
  );
}
