/**
 * The Onboarding feature tour.
 *
 * TODOs:
 *  - Abstract out our Copy-fetching here to lead to easier customization later.
 */
import { Button } from '@checkrx/pay-component-library';
import { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { Navigate } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';

import { useCompleteOnboardingTutorial } from '@/services/onboarding';
import { useCustomerFromProfile } from '@/services/useWorkerProfile';
import { trackAmplitudeEvent } from '@/shared/analytics';

import drawing1 from '@/shared/assets/tutorial-drawing-1.svg';
import drawing2 from '@/shared/assets/tutorial-drawing-2.svg';
import drawing3 from '@/shared/assets/tutorial-drawing-3.svg';

import DiscreteProgBar from '@/shared/components/DiscreteProgBar';
import { Bold } from '@/shared/components/Text.styled';

import {
  BottomContainer,
  ButtonRow,
  ContentBox,
  Drawing,
  Headline,
  MiddleContainer,
  OnboardingText,
  OnboardingView,
} from './TutorialPage.styled';

export default function TutorialPage() {
  const [pageNumber, setPageNumber] = useState(0);
  const { mutate: completeWorkerOnboarding, isSuccess } = useCompleteOnboardingTutorial();

  const { data: customer } = useCustomerFromProfile();
  const { humanReadableName, preferences } = customer || {};
  const { images = {}, copy = {} } = preferences || {};
  const { onboarding: onboardingImages = {} } = images;
  const { onboarding: onboardingCopy = {} } = copy;
  //   const [runPostCompleteWorkerOnboarding, postCompleteOnboardingResult] =
  //     usePostCompleteWorkerOnboardingMutation();

  const swipeHandlers = useSwipeable({
    onSwipedRight: () => {
      if (pageNumber > 0) setPageNumber(pageNumber - 1);
    },
    onSwipedLeft: () => {
      if (pageNumber < 2) setPageNumber(pageNumber + 1);
    },
  });

  const renderMarkdown = (text: string) => {
    return <ReactMarkdown>{text}</ReactMarkdown>;
  };

  const handleOnBoardingCompleteClick = () => {
    completeWorkerOnboarding();
  };

  const handleSkipButtonClick = () => {
    trackAmplitudeEvent('Onboarding Skipped');
    completeWorkerOnboarding();
  };

  if (isSuccess) {
    return <Navigate to="/onboarding/confirm" />;
  }

  let pageContent;
  if (pageNumber === 0) {
    pageContent = (
      <>
        <Drawing
          src={onboardingImages.pageOne?.url || drawing1}
          width={onboardingImages.pageOne?.width}
          height={onboardingImages.pageOne?.height}
        />
        <ContentBox>
          <MiddleContainer>
            <Headline>{onboardingCopy?.pageOne?.header ?? 'Get Paid Today'}</Headline>
            {!!onboardingCopy?.pageOne?.body && onboardingCopy?.pageOne ? (
              <OnboardingText> {renderMarkdown(onboardingCopy.pageOne.body)} </OnboardingText>
            ) : (
              <OnboardingText>
                {humanReadableName} partners with Checkr Pay so you can access your earnings
                <Bold> instantly</Bold>. No more waiting for direct deposit to clear.
              </OnboardingText>
            )}
          </MiddleContainer>
          <BottomContainer>
            <DiscreteProgBar length="150px" nodes={[true, false, false]} />
            <ButtonRow>
              <Button
                className="onboarding-skip-btn"
                text="Skip"
                width="67px"
                colorVariant="dark"
                sizeVariant="big"
                onClick={handleSkipButtonClick}
              />
              <Button
                className="onboarding-next-pg-btn"
                icon="arrow-right"
                width="67px"
                colorVariant="brand"
                sizeVariant="big"
                onClick={() => {
                  setPageNumber(pageNumber + 1);
                }}
              />
            </ButtonRow>
          </BottomContainer>
        </ContentBox>
      </>
    );
  } else if (pageNumber === 1) {
    pageContent = (
      <>
        <Drawing
          src={onboardingImages.pageTwo?.url || drawing2}
          width={onboardingImages.pageTwo?.width}
          height={onboardingImages.pageTwo?.height}
        />
        <ContentBox>
          <MiddleContainer>
            <Headline>{onboardingCopy?.pageTwo?.header ?? 'Hassle-free Banking'}</Headline>
            {!!onboardingCopy?.pageTwo?.body && onboardingCopy.pageTwo ? (
              <OnboardingText> {renderMarkdown(onboardingCopy.pageTwo.body)} </OnboardingText>
            ) : (
              <OnboardingText>
                Checkr Pay provides a <Bold>free digital wallet and debit card</Bold>.
                Your {humanReadableName} earnings are securely deposited into your Checkr Pay
                account, which you can activate and manage via our web app, or right in your
                {humanReadableName} app, if you&apos;re provided one.
              </OnboardingText>
            )}
          </MiddleContainer>
          <BottomContainer>
            <DiscreteProgBar length="150px" nodes={[false, true, false]} />
            <ButtonRow>
              <Button
                className="onboarding-skip-btn"
                text="Skip"
                width="67px"
                colorVariant="dark"
                sizeVariant="big"
                onClick={handleOnBoardingCompleteClick}
              />
              <Button
                className="onboarding-next-pg-btn"
                icon="arrow-right"
                width="67px"
                colorVariant="brand"
                sizeVariant="big"
                onClick={() => {
                  setPageNumber(pageNumber + 1);
                }}
              />
            </ButtonRow>
          </BottomContainer>
        </ContentBox>
      </>
    );
  } else if (pageNumber === 2) {
    pageContent = (
      <>
        <Drawing
          src={onboardingImages.pageThree?.url || drawing3}
          width={onboardingImages.pageThree?.width}
          height={onboardingImages.pageThree?.height}
        />
        <ContentBox>
          <MiddleContainer>
            <Headline>{onboardingCopy?.pageThree?.header ?? 'Free Debit Card'}</Headline>
            {!!onboardingCopy?.pageThree?.body && onboardingCopy.pageThree ? (
              <OnboardingText> {renderMarkdown(onboardingCopy.pageThree.body)} </OnboardingText>
            ) : (
              <OnboardingText>
                Enjoy your free Checkr Pay <Bold>Visa Business Debit Card</Bold>.
                Use your card wherever Visa is accepted worldwide or withdraw cash from supported
                ATMs. With instant access and no fees, managing your money has never been easier.
              </OnboardingText>
            )}
          </MiddleContainer>
          <BottomContainer>
            <DiscreteProgBar length="150px" nodes={[false, false, true]} />
            <ButtonRow>
              <Button
                className="onboarding-finish-btn"
                text="Let's go!"
                iconRight
                icon="arrow-right"
                width="100%"
                colorVariant="brand"
                sizeVariant="big"
                onClick={handleOnBoardingCompleteClick}
              />
            </ButtonRow>
          </BottomContainer>
        </ContentBox>
      </>
    );
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <OnboardingView {...swipeHandlers}> {pageContent} </OnboardingView>;
}
