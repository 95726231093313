
import { fontSizes } from '@/shared/styles';
import styled from 'styled-components';

export const ListItem = styled.li`
  font-size: ${fontSizes.medium};
  display: flex;
  height: 30px;
  align-items: center;

  svg {
    display: inline-block;
    height: 20px;
    margin-right: 5px;
    width: 20px;
  }
`;

export const ListItemWarning = styled(ListItem)`
  height: auto;
`;

export const UnorderedList = styled.ul`
  padding: .5em 0px;
`;
