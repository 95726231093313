import { colors } from '@/shared/styles';
import styled from 'styled-components';

export const FormContainer = styled.div`
  width: 100%;
  padding: 0 12px;
`;

export const FieldContainer = styled.div`
  width: 100%;
  height: 40px;
  iframe {
    width: 100%;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

export const SubmitButton = styled.button`
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  width: calc(100%-38px);
  height: 36px;
  min-height: 36px;
  min-width: 36px;
  border-radius: 7px;
  gap: 6px;
  padding: 0 9px;
  border: none;
  background: ${colors.primaryBlue};
  color: ${colors.trueWhite};
  font-size: 0.875rem;
  font-weight: 600;
  cursor: pointer;
  z-index: 1000;
  &:hover:not(:disabled) {
    filter: brightness(0.8);
  }

  &:disabled {
    background-color: ${colors.primaryGreyLight};
    cursor: not-allowed;
  }
`;
