import { useState } from 'react';
import { DataContent, DataLabel, PiiRow } from '../shared/confirm-pages.styled';
import { ConfirmProfileInput, ConfirmProfileInputProps } from '../shared/ConfirmProfileInput';
import { personalDetailsWithSsnValidation } from './validations';

type InputProps = ConfirmProfileInputProps & {
  isValue?: boolean;
  label: string;
};

export type PersonalDetails = {
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  dob: string;
  ssn: string;
};

interface PersonalDetailsProps {
  value: PersonalDetails;
  onChange: (personalDetails: PersonalDetails) => void;
  contactPhone: string;
  taxPayerId: string;
}

export function PersonalDetailsForm({
  value,
  onChange,
  contactPhone,
  taxPayerId,
}: PersonalDetailsProps) {
  const [personalDetails, setPersonalDetails] = useState(value);

  const handleChange = (key: keyof PersonalDetails, v: string) => {
    const personalDetailsCopy = { ...personalDetails };
    personalDetailsCopy[key] = v;
    setPersonalDetails(personalDetailsCopy);
    onChange(personalDetailsCopy);
  };

  const personalDetailsValidation = personalDetailsWithSsnValidation.validate(value, {
    abortEarly: false,
  });

  const getErrorState = (key: keyof typeof personalDetails) => {
    const inputErrorIdx = personalDetailsValidation?.error?.details.findIndex(
      (d) => key === d.path[0]
    ) as number;
    if (inputErrorIdx === -1) return {};

    return {
      validation: () => !personalDetailsValidation.error?.details[inputErrorIdx],
      errorText: personalDetailsValidation.error?.details[inputErrorIdx]?.message,
    };
  };

  const inputs: InputProps[] = [
    {
      label: 'First name:',
      value: personalDetails.firstName,
      onChange: (v: string) => handleChange('firstName', v),
      placeholder: 'First name',
      ...getErrorState('firstName'),
      className: 'confirm-pii-first-name',
      fullviewCaptureEnabled: true,
      type: 'text',
    },
    {
      label: 'Middle name:',
      value: personalDetails.middleName,
      onChange: (v: string) => handleChange('middleName', v),
      placeholder: 'Middle name',
      ...getErrorState('middleName'),
      className: 'confirm-pii-middle-name',
      fullviewCaptureEnabled: true,
      type: 'text',
    },
    {
      label: 'Last name:',
      value: personalDetails.lastName,
      onChange: (v: string) => handleChange('lastName', v),
      placeholder: 'Last name',
      ...getErrorState('lastName'),
      className: 'confirm-pii-last-name',
      fullviewCaptureEnabled: true,
      type: 'text',
    },
    {
      label: 'Email:',
      value: personalDetails.email,
      onChange: (v: string) => handleChange('email', v),
      placeholder: 'Email',
      ...getErrorState('email'),
      className: 'confirm-pii-email',
      fullviewCaptureEnabled: true,
      type: 'text',
    },
    {
      label: 'Date of birth:',
      value: personalDetails.dob,
      onChange: (v: string) => handleChange('dob', v),
      placeholder: 'Date of birth',
      type: 'date',
      ...getErrorState('dob'),
      className: 'confirm-pii-dob',
      fullviewCaptureEnabled: true,
    },
    {
      label: 'Phone number:',
      value: contactPhone,
      onChange: () => undefined,
      isValue: true,
      placeholder: 'Phone number',
      className: 'confirm-pii-phone-number',
      fullviewCaptureEnabled: true,
      type: 'text',
    },
    {
      label: taxPayerId ? 'Last four of SSN:' : 'Full SSN:',
      value: taxPayerId ? taxPayerId : personalDetails.ssn,
      onChange: (v: string) => handleChange('ssn', v),
      placeholder: 'Full SSN',
      maxLength: 11,
      fullviewCaptureEnabled: false,
      isValue: !!taxPayerId,
      format: (v) => {
        let val = v.replace(/\D/g, '');
        val = val.replace(/^(\d{3})(\d{1,2})/, '$1-$2');
        val = val.replace(/^(\d{3})-(\d{2})(.+)/, '$1-$2-$3');
        v = val.substring(0, 11);
        return val;
      },
      ...getErrorState('ssn'),
      className: 'confirm-pii-ssn',
      type: 'text',
    },
  ];

  return (
    <>
      {inputs.map((input) => {
        if (input.isValue) {
          return (
            <PiiRow key={input.label}>
              <DataLabel>{input.label}</DataLabel>
              <DataContent className={input.fullviewCaptureEnabled ? undefined : 'fullview-hide'}>
                {input.value}
              </DataContent>
            </PiiRow>
          );
        }
        return (
          <PiiRow key={input.label}>
            <DataLabel>{input.label}</DataLabel>
            <ConfirmProfileInput {...(input as ConfirmProfileInputProps)} height="32px" />
          </PiiRow>
        );
      })}
    </>
  );
}
