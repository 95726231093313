import { useCashbackSummary } from '@/services/useCashback';
import { useAddPayoutMethod } from '@/services/usePayoutMethod';
import { ReactComponent as Wallet } from '@/shared/assets/wallet.svg';
import { PayoutMethod, ProfileStatus, WorkerProfile } from '@/shared/types';
import { Button } from '@checkrx/pay-component-library';
import { FC, useState } from 'react';
import { Bold, ErrorText, MediumWarningText } from '../../Text.styled';
import { PaymentMethodBadgeT, PaymentMethodChoice } from '../PaymentMethodChoice';
import { ConfirmationButtonsContainer } from '../../ButtonContainer.styled';

type Props = {
  workerProfile: WorkerProfile;
};

export const CheckrWalletChoice: FC<Props> = ({ workerProfile }) => {
  const { mutate: addExternalAccount, isLoading, isError } = useAddPayoutMethod();

  const handleOnClick = async () => {
    addExternalAccount({
      payoutMethod: PayoutMethod.CheckrPayBankAccount,
    });
    setShowConfirmation(false);
  };
  const [showConfirmation, setShowConfirmation] = useState(false);
  const {
    data: cashbackSummary,
    isLoading: isCashbackSummaryLoading,
    isError: cashbackLoadHasErrors,
  } = useCashbackSummary();

  if (isCashbackSummaryLoading) {
    return null;
  }

  if (cashbackLoadHasErrors || isError) {
    return (
      <div>
        <ErrorText> Failed to load. Try again!</ErrorText>
      </div>
    );
  }

  const { rewardedCategoryDescription } = cashbackSummary;
  const selectedPayoutMethod = workerProfile?.profile?.payoutMethod?.selected;
  const onboarded = workerProfile?.profile?.status === ProfileStatus.Onboarded;

  const badges = [
    selectedPayoutMethod === PayoutMethod.CheckrPayBankAccount ? 'active' : null,
  ].filter(Boolean) as PaymentMethodBadgeT[];
  return (
    <PaymentMethodChoice
      title="Checkr Wallet"
      titleIcon={<Wallet />}
      badges={['recommended', ...badges]}
      bulletPoints={[
        <>
          <Bold>Free</Bold>,&nbsp;<Bold>instant&nbsp;</Bold> payouts
        </>,
        'Free debit card',
        `Earn cashback on ${rewardedCategoryDescription} purchases`,
      ]}
    >
      {selectedPayoutMethod === PayoutMethod.CheckrPayBankAccount ? (
        onboarded ? (
          <p>This is selected and will activate automatically on your first payout.</p>
        ) : null
      ) : (
        <>
          {showConfirmation ? (
            <>
              <MediumWarningText>
                This will disable payouts via Direct Deposit, and enable instant payouts via Checkr
                Wallet.
              </MediumWarningText>
              <ConfirmationButtonsContainer>
                <Button
                  width="45%"
                  text="Confirm"
                  colorVariant="dark"
                  sizeVariant="small"
                  disabled={isLoading}
                  onClick={handleOnClick}
                />
                <Button
                  width="45%"
                  text="Cancel"
                  colorVariant="light"
                  sizeVariant="small"
                  disabled={isLoading}
                  onClick={() => setShowConfirmation(false)}
                />
              </ConfirmationButtonsContainer>
            </>
          ) : (
            <Button
              width="100%"
              text="Enable instant payouts"
              colorVariant="light"
              sizeVariant="small"
              disabled={isLoading}
              onClick={() => setShowConfirmation(true)}
            />
          )}
        </>
      )}
    </PaymentMethodChoice>
  );
};
