import { Payout } from '@/shared/types';
import InvalidPushToCardDirectDepositPayoutDestinationNotice from './InvalidPushToCardDirectDepositPayoutDestinationNotice';
import InvalidStandardDirectDepositPayoutDestinationNotice from './InvalidStandardDirectDepositPayoutDestinationNotice';
import WaitingOnManualIssuanceNotice from './WaitingOnManualIssuanceNotice';
import WaitingOnWorkerAccountActivationNotice from './WaitingOnWorkerAccountActivationNotice';
import WaitingOnWorkerAccountReactivationNotice from './WaitingOnWorkerAccountReactivationNotice';

type Props = {
  payouts: Array<Payout>;
};

const PayoutNoticeComponents = [
  // payout failure related
  InvalidPushToCardDirectDepositPayoutDestinationNotice,
  InvalidStandardDirectDepositPayoutDestinationNotice,
  // payout queueing related
  WaitingOnManualIssuanceNotice,
  WaitingOnWorkerAccountActivationNotice,
  WaitingOnWorkerAccountReactivationNotice,
];

export default function PayoutNotices({ payouts }: Props) {
  const NoticeComponents = PayoutNoticeComponents.map((NoticeComponent, i) => {
    return <NoticeComponent key={i} payouts={payouts} />;
  });

  return <>{NoticeComponents}</>;
}
