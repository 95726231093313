import styled from 'styled-components';

import { useAcknowledgeMessage } from '@/services/useInAppMessages';
import { useFeatureFlagsFromProfile } from '@/services/useWorkerProfile';
import { ReactComponent as CloseEx } from '@/shared/assets/close-ex.svg';
import { colors, fontSizes, fontWeights } from '@/shared/styles';
import { InAppMessage, InAppMessageType } from '@/shared/types';
import { useState } from 'react';
import { MessageContent } from './MessageContent';

const FixedContainer = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  min-height: 450px;
  max-width: 400px;
  margin: auto;
  transform: translateZ(0);
  z-index: 20;
  pointer-events: none;
`;

const CloseButton = styled.button`
  align-self: start;
  width: fit-content;
  min-width: 18px;
  height: fit-content;
  color: ${colors.primaryGreyDark};
  margin-right: -10px;
  pointer-events: auto;
  background: none;
  border: none;
  cursor: pointer;
  &:hover {
    color: ${colors.primaryBlue};
    transition: 0.2s;
  }
`;

const RelContainer = styled.div`
  position: relative;
  height: fit-content;
  max-width: 100%;
  padding: 20px;
  z-index: 20;
  pointer-events: auto;
`;

const StyledBanner = styled.div`
  width: 95%;
  margin: 20px auto;
  min-height: 32px;
  white-space: initial;
  border-radius: 6px;
  text-align: center;
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  font-size: ${fontSizes.smallMedium};
  font-weight: ${fontWeights.bold};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  color: ${(p) => p.theme.colors.text.primary};
  background: ${colors.backgroundGrey};
  border: 2px solid ${colors.accentTeal};
`;

export function MessageDisplay({
  messageId,
  messageType,
  expiresAt,
  messageData,
}: {
  messageId: InAppMessage['_id'];
  messageType: InAppMessage['messageType'];
  expiresAt: InAppMessage['expiresAt'];
  messageData?: InAppMessage['messageData'];
}) {
  const { data: flags } = useFeatureFlagsFromProfile();
  const [wasOpened, setWasOpened] = useState(false);
  const { mutate: acknowledgeMessage } = useAcknowledgeMessage();

  if (
    flags?.customerFlags?.taxesDisabled.enabled &&
    (messageType === InAppMessageType.TaxesAreComing ||
      messageType === InAppMessageType.TaxesDocumentFiled)
  ) {
    return null;
  }

  return (
    <FixedContainer>
      <RelContainer>
        <StyledBanner>
          <MessageContent
            wasOpened={wasOpened}
            setWasOpened={setWasOpened}
            messageType={messageType}
            messageId={messageId}
            expiresAt={expiresAt}
            messageData={messageData}
          />
          <CloseButton
            onClick={() => {
              acknowledgeMessage({ messageId, isOpened: wasOpened });
            }}
          >
            <CloseEx />
          </CloseButton>
        </StyledBanner>
      </RelContainer>
    </FixedContainer>
  );
}
