import { PayoutMethod, Transaction } from '@/shared/types';

export const hideBalance = (transaction: Transaction) => {
  if (!transaction.metadata?.payoutMethod) {
    return true;
  }
  return [PayoutMethod.PushToCardDirectDeposit, PayoutMethod.ACHDirectDeposit].includes(
    transaction.metadata?.payoutMethod
  );
};
