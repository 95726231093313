import InvalidDirectDepositPayoutDestinationNotice from '@/shared/components/PayoutNotices/InvalidDirectDepositPayoutDestinationNotice';
import { Payout, PayoutMethod } from '@/shared/types';

type Props = {
  payouts: Array<Payout>;
};

export default function InvalidPushToCardDirectDepositPayoutDestinationNotice({ payouts }: Props) {
  return (
    <InvalidDirectDepositPayoutDestinationNotice
      payouts={payouts}
      directDepositPayoutMethod={PayoutMethod.PushToCardDirectDeposit}
    />
  );
}
