import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { hideBalance } from '@/pages/transactions/utils';
import { SmallMediumText, TitleText } from '@/shared/components/Text.styled';
import TransactionSymbol from '@/shared/components/TransactionSymbol';
import { formatCentsAsCurrency } from '@/shared/helpers';
import { colors, fontSizes, fontWeights } from '@/shared/styles';
import { PayoutMethod, Transaction, TransactionDirection, TransactionStatus } from '@/shared/types';

const TransactionItemButton = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  border: none;
  cursor: pointer;
  background: ${(props) => props.theme.colors.backgrounds.app};
`;

const SymbolWrapper = styled.div`
  margin-right: 16px;
  flex-shrink: 0;
`;

const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 0;
`;

const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
`;

const BottomRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Name = styled(TitleText)`
  font-size: ${fontSizes.medium};
  font-weight: ${fontWeights.bold};
  color: ${colors.primaryBlue};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 8px;
`;

const Amount = styled(TitleText)`
  font-size: ${fontSizes.medium};
  font-weight: ${fontWeights.bold};
  white-space: nowrap;
  flex-shrink: 0;
`;

const DateText = styled(SmallMediumText)`
  color: ${colors.primaryGreyMediumDark};
`;

const Balance = styled(SmallMediumText)`
  color: ${colors.primaryGreyMediumDark};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StatusMessage = styled.div`
  font-size: ${fontSizes.small};
  background: ${colors.primaryGreyLight};
  border-radius: 4px;
  padding: 2px 6px;
  color: ${colors.primaryBlue};
  margin-right: 8px;
`;

const FeeBadge = styled.div`
  font-size: ${fontSizes.small};
  background-color: #ffd700;
  color: ${colors.trueBlack};
  border-radius: 4px;
  padding: 2px 6px;
`;

const LeftBottomContent = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;
  flex: 1;
  min-width: 0;
`;

function getTransactionStatusMessage(transaction: Transaction) {
  switch (transaction.transactionStatus) {
    case TransactionStatus.Authorized:
      return 'Card Authorization Hold';
    case TransactionStatus.Pending:
      if (transaction.metadata?.payoutMethod === PayoutMethod.ACHDirectDeposit) {
        return 'Pending - Direct Deposit';
      } else {
        return 'Pending';
      }
    case TransactionStatus.Returned:
      return 'Returned';
    default:
      return null;
  }
}

interface TransactionListItemProps {
  className?: string;
  txn: Transaction;
}

export default function TransactionListItem({ className, txn }: TransactionListItemProps) {
  const navigate = useNavigate();
  const balance = txn.balance ?? 0;

  const payoutMethodFee = Number(txn.metadata?.payoutMethodFeeCents ?? 0);
  const formattedAmount = formatCentsAsCurrency(txn.amount - payoutMethodFee);
  const amountDirection = txn.direction === TransactionDirection.Credit ? '+' : '-';
  const statusMessage = getTransactionStatusMessage(txn);
  const text =
    (txn?.metadata?.annotation || '').length > 0 ? txn?.metadata?.annotation : txn.summary;

  return (
    <TransactionItemButton
      className={className}
      type="button"
      onClick={() => {
        navigate(`/transactions/details/${txn.transactionId}`);
      }}
    >
      <SymbolWrapper>
        <TransactionSymbol type={txn.transactionType} direction={txn.direction} />
      </SymbolWrapper>
      <ContentWrapper>
        <TopRow>
          <Name>{text}</Name>
          <Amount>
            {amountDirection} {formattedAmount}
          </Amount>
        </TopRow>
        <BottomRow>
          <LeftBottomContent>
            {!statusMessage || payoutMethodFee <= 0 ? (
              <DateText>{dayjs(txn.createdAt).format('MMM D, YYYY')}</DateText>
            ) : null}
            {statusMessage && <StatusMessage>{statusMessage}</StatusMessage>}
            {payoutMethodFee > 0 && (
              <FeeBadge>{formatCentsAsCurrency(payoutMethodFee)} fee</FeeBadge>
            )}
          </LeftBottomContent>
          {balance && !hideBalance(txn) ? (
            <Balance>Balance: {formatCentsAsCurrency(balance)}</Balance>
          ) : null}
        </BottomRow>
      </ContentWrapper>
    </TransactionItemButton>
  );
}
