import { ReactComponent as Wallet } from '@/shared/assets/wallet.svg';
import { Button } from '@checkrx/pay-component-library';
import { useNavigate } from 'react-router-dom';
import { PaymentMethodChoice } from '../PaymentMethodChoice';

export const WalletClosedChoice = () => {
  const navigate = useNavigate();

  return (
    <PaymentMethodChoice title="Checkr Wallet Disabled" titleIcon={<Wallet />}>
      <p>Your Checkr Wallet has been closed. Tap below to reopen your wallet.</p>
      <Button
        width="100%"
        sizeVariant="small"
        colorVariant="dark"
        text="Reopen Wallet"
        onClick={() => navigate('/bank-account-information')}
      />
    </PaymentMethodChoice>
  );
};
