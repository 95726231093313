import { ReactComponent as GreenTickIcon } from '@/shared/assets/green-tick.svg';
import { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { Badge } from '../Badge.styled';
import { Bold } from '../Text.styled';
import { BadgeRow, ChoiceHeader, PaymentMethodsChoiceContainer } from './PaymentMethodPanel.styled';
import { ListItem } from '../List.styled';

export type PaymentMethodBadgeT = 'recommended' | 'active';

const ChildContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

type Props = {
  title: string;
  titleIcon: ReactNode;
  badges?: PaymentMethodBadgeT[];
  bulletPoints?: ReactNode[];
  children?: ReactNode;
};

export const PaymentMethodChoice: FC<Props> = ({
  title,
  titleIcon,
  badges = [],
  bulletPoints = [],
  children,
}) => {
  const badgeMap = {
    recommended: (
      <Badge dark key="badge_recommended">
        Recommended
      </Badge>
    ),
    active: <Badge key="badge_active">Active</Badge>,
  };
  return (
    <PaymentMethodsChoiceContainer>
      <ChoiceHeader>
        <div>
          <span>{titleIcon}</span>
          <Bold>{title}</Bold>
        </div>
        {badges.length > 0 && <BadgeRow>{badges.map((b) => badgeMap[b])}</BadgeRow>}
      </ChoiceHeader>
      <ul>
        {bulletPoints.map((bp, idx) => {
          return (
            <ListItem key={`bullet_${idx}`}>
              <GreenTickIcon />
              {bp}
            </ListItem>
          );
        })}
      </ul>
      <ChildContainer>{children}</ChildContainer>
    </PaymentMethodsChoiceContainer>
  );
};
