import { WarningText } from '@/shared/components/Text.styled';
import { addressMightBePoBox } from '@/shared/helpers';
import { Check } from '@checkrx/pay-component-library';
import styled from 'styled-components';
import {
  DataContent,
  DataLabel,
  Group,
  OptionalInputRow,
  PiiRow,
} from '../shared/confirm-pages.styled';
import { ConfirmProfileInput, ConfirmProfileInputProps } from '../shared/ConfirmProfileInput';

import { useState } from 'react';
import { AutocompleteAddress } from './AutocompleteAddress';
import { addressValidationSchema } from './validations';

const PoBoxWarningMessage = styled.div`
  text-align: center;
`;

export type Address = {
  street: string;
  street2?: string;
  city: string;
  state: string;
  postalCode: string;
};

interface AddressProps {
  value: Address;
  onChange: (address: Address) => void;
}

export function AddressForm({ value, onChange }: AddressProps) {
  const [manualEntry, setManualEntry] = useState(false);
  const country = 'US';

  const handleChange = (key: keyof Address, v: string) => {
    const addressCopy = { ...(value || {}) };
    addressCopy[key] = v;
    onChange(addressCopy);
  };

  const addressValidation = addressValidationSchema.validate(value || {}, {
    abortEarly: false,
  });

  const getErrorState = (key: keyof typeof value) => {
    const inputErrorIdx = addressValidation?.error?.details.findIndex(
      (d) => key === d.path[0]
    ) as number;
    if (inputErrorIdx === -1) return {};

    return {
      validation: () => !addressValidation.error?.details[inputErrorIdx],
      errorText: addressValidation.error?.details[inputErrorIdx]?.message,
    };
  };

  const inputs: (ConfirmProfileInputProps & { label: string })[] = [
    {
      label: 'Street:',
      value: value?.street,
      onChange: (v) => handleChange('street', v),
      placeholder: '123 Main St',
      ...getErrorState('street'),
      type: 'text',
      key: 'street',
    },
    {
      label: 'Street 2:',
      value: value?.street2,
      onChange: (v) => handleChange('street2', v),
      placeholder: 'Apt 123',
      ...getErrorState('street2'),
      type: 'text',
      key: 'street2',
    },
    {
      label: 'City:',
      value: value?.city,
      onChange: (v) => handleChange('city', v),
      placeholder: 'City',
      ...getErrorState('city'),
      type: 'text',
      key: 'city',
    },
    {
      label: 'State:',
      value: value?.state,
      onChange: (v) => handleChange('state', v),
      placeholder: 'State',
      ...getErrorState('state'),
      type: 'text',
      key: 'state',
    },
    {
      label: 'Postal code:',
      value: value?.postalCode,
      onChange: (v) => handleChange('postalCode', v),
      placeholder: 'Postal codes',
      maxLength: 5,
      ...getErrorState('postalCode'),
      type: 'text',
      key: 'postalCode',
    },
  ];

  return (
    <Group key="profile-address">
      {value?.street && value?.street?.length > 0 && addressMightBePoBox(value?.street) && (
        <PoBoxWarningMessage>
          <WarningText>
            Please note; PO Boxes are currently not supported as a primary address. Please enter
            this as the mailing address.
          </WarningText>
        </PoBoxWarningMessage>
      )}

      <OptionalInputRow onClick={() => setManualEntry(!manualEntry)}>
        <Check checked={manualEntry} />
        <DataLabel>I want to enter my address manually</DataLabel>
      </OptionalInputRow>

      {manualEntry ? (
        inputs.map((i) => (
          <PiiRow key={i.label}>
            <DataLabel>{i.label}</DataLabel>
            <ConfirmProfileInput {...(i as ConfirmProfileInputProps)} />
          </PiiRow>
        ))
      ) : (
        <>
          <AutocompleteAddress
            onSelect={(s) => onChange(s)}
            label="Search Address"
            placeholder="Search Address"
            value={value || {}}
          />
          <PiiRow>
            <DataLabel>Street:</DataLabel>
            <DataContent>{value?.street || '----'}</DataContent>
          </PiiRow>
          {value?.street2 && (
            <PiiRow>
              <DataLabel>Street 2:</DataLabel>
              <DataContent>{value?.street2}</DataContent>
            </PiiRow>
          )}
          <PiiRow>
            <DataLabel>City:</DataLabel>
            <DataContent>{value?.city || '----'}</DataContent>
          </PiiRow>
          <PiiRow>
            <DataLabel>State:</DataLabel>
            <DataContent>{value?.state || '----'}</DataContent>
          </PiiRow>
          <PiiRow>
            <DataLabel>Postal code:</DataLabel>
            <DataContent>{value?.postalCode || '----'}</DataContent>
          </PiiRow>

          <PiiRow>
            <DataLabel>Country:</DataLabel>
            <DataContent>{country}</DataContent>
          </PiiRow>
        </>
      )}
    </Group>
  );
}
