import { Payout, PayoutMethod } from '@/shared/types';
import InvalidDirectDepositPayoutDestinationNotice from './InvalidDirectDepositPayoutDestinationNotice';

type Props = {
  payouts: Array<Payout>;
};

export default function InvalidStandardDirectDepositPayoutDestinationNotice({ payouts }: Props) {
  const noticeProps = {
    payouts,
    directDepositPayoutMethod: PayoutMethod.ACHDirectDeposit,
  };

  return <InvalidDirectDepositPayoutDestinationNotice {...noticeProps} />;
}
